import { defineStore } from 'pinia'
import useCustomFetch from '~/composables/useCustomFetch'
import { STATUS_OK } from '~/constants/apiStatus'
import { useUserStore, useModalStore } from '~/stores'

export const useGamesStore = defineStore('gamesStore', () => {
    const fetcher = useCustomFetch()
    const useUserStoreInstance = useUserStore()
    const useModalStoreInstance = useModalStore()

    const games = ref([])
    const getGames = async (queryParams) => {
        try {
            const { data, pending, error } = await fetcher.get(
                '/game/search/',
                { ...queryParams, limit: 20 },
                20 * 1000
            )

            if (data.value?.status === STATUS_OK) {
                if (queryParams.page > 1) {
                    games.value.items.push(...data.value.data.items)
                } else {
                    games.value = data.value.data
                }
            }
            return { data: games, pending, error }
        } catch (error) {
            console.log('gamesStore', error)
        }
    }

    const hotSlotGames = ref([])
    const getHotSlotGames = async () => {
        try {
            const { data } = await fetcher.get(
                '/game/search/?type=slots&filter=hot&keyword&page=1&limit=5'
            )

            if (data.value?.status === STATUS_OK) {
                hotSlotGames.value = data.value.data
            }
        } catch (error) {
            console.log('getHotSlotGames', error)
        }
    }

    const getGameUrl = async (queryParams) => {
        if (useUserStoreInstance.isLoggedIn) {
            const { data } = await fetcher.get('/gameUrl', queryParams)
            if (data.value?.status === 'SHOW_MESSAGE') {
                useModalStoreInstance.showGameDenyModal = true
                return { data: ref(null) }
            } else {
                return { data: data }
            }
        } else {
            useModalStoreInstance.showLoginModal = true
            // return an empty ref
            return { data: ref(null) }
        }
    }
    const getGameUrlNoAuth = async (queryParams) => {
        const { data } = await fetcher.get('/gameUrl', queryParams)
        if (data.value?.status === 'SHOW_MESSAGE') {
            useModalStoreInstance.showGameDenyModal = true
            return { data: ref(null) }
        } else {
            return { data: data }
        }
    }

    return {
        getGames,
        getGameUrl,
        getGameUrlNoAuth,
        games,

        getHotSlotGames,
        hotSlotGames,
    }
})
